// mixin 
export default {
    data() {
        return {}
    },
    mounted() {
        this.checkExpiry()
    },
    methods: {
        checkExpiry() {
            const expiry = this.$store.state.token_expiry
            if (!expiry) return;
            const timeToExpire = (expiry - new Date()) / 1000;
            //turn timeToExpire to minutes
            const minutesToExpire = Math.floor(timeToExpire / 60);
            //turn timeToExpire to seconds
            const secondsToExpire = Math.floor(timeToExpire % 60);
            // see if token expiry is imminent
            if (parseInt(minutesToExpire) < 15 && parseInt(minutesToExpire) > 0) {
                this.$store.dispatch('refreshToken')
            }
        }
    }
}