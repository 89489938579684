<template>
    <div id="app">
        <div v-if="loading">
            <div class="d-flex justify-content-center mb-3">
                <b-spinner v-if="loading" label="Loading..."
                           style="width: 5em; height: 5em; margin: auto; margin-top: 10em"></b-spinner>
            </div>
            <p class="text-center mt-5">Loading...</p>
        </div>
        <template v-else>
            <LoginForm v-if="!logined"></LoginForm>
            <component v-else :is="layout">
                <router-view></router-view>
            </component>
        </template>
    </div>
</template>

<script>
import LoginForm from "./pages/LoginForm.vue";
import DashboardIndex from "./pages/DashboardIndex.vue";
export default {
    name: "App",
    components: {
        LoginForm,
        DashboardIndex,
    },
    data() {
        return {
            loading: true,
        };
    },
    mounted() {
        this.loadToken();
    },
    computed: {
        logined: function () {
            return this.$store.state.logined;
        },
        layout() {
            return "default-layout";
        },
    },
    methods: {
        checkLogin() {
            axios
                .get("/api/user", { withCredentials: true })
                .then((response) => {
                    this.$store.dispatch("login");
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.$store.commit("logout", false);
                });
        },
        loadToken() {
            // from session storage
            if (!this.$cookies.isKey("user_session")) {
                this.$store.commit("logout", false);
                this.loading = false;
                return;
            }
        
            const user_session = this.$cookies.get("user_session");
            const token = user_session.access_token;
            const user = user_session.user;
            const expiry = user_session.token_expiry;

            // check if token expired

            if (token && user && expiry && expiry > Date.now()) {
                const config  =
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                };

                axios.get("/api/user", config).then((response) => {
                    this.$store.commit("login", {
                    access_token: token,
                    user: user,
                    token_expiry: expiry / 1000,
                    } );
                    this.loading=false;
                }).catch((error) => {
                    this.$store.commit("logout", false);
                    this.loading=false;
                    console.log(error.response.data.message)
                });

                return;
            }
            console.log("token expiresd", expiry, Date.now())
            this.loading = false;
        },
    },
};
</script>

<style></style>
