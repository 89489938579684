<template>
  <b-container fluid="md" style="max-width: 500px">
    <div style="display: flex; width: 100%; justify-content: center; margin: 50px 0">
      <img src="/img/Logo-03.svg" alt="Logo" style="width: 200px" />
    </div>
    <b-overlay :show="loading">
      <b-form @submit.prevent="login">
        <b-form-group label="Email address:" label-for="email">
          <b-form-input id="email" v-model="email" type="email" required></b-form-input>
        </b-form-group>

        <b-form-group label="Password:" label-for="password">
          <b-form-input
            id="password"
            v-model="password"
            type="password"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Log In</b-button>
      </b-form>
    </b-overlay>
    <div style="height:30vh"></div>
    <div style="width:100%;text-align:center">
        {{ env }} build.
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    login() {
      this.loading=true;
      axios
        .post("/api/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.$store.commit("login", response.data);
          this.loading=false;
        })
        .catch((error) => {
          alert("Login failed")
          this.loading=false;
        });
    },
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENVIRONMENT;
    },
  }
};
</script>
