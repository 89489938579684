import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expires: '2h'})
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        logined: false,
        access_token: null,
        user: null,
        token_expiry: null,
    },
    mutations: {
        login(state, data) {
            state.logined = true;
            state.access_token = data.access_token;
            state.user = data.user;
            state.token_expiry = data.token_expiry * 1000;
            // store the token in session storge
            Vue.$cookies.set('user_session', JSON.stringify({
                access_token: data.access_token,
                user: data.user,
                token_expiry: data.token_expiry * 1000
            }), null, null, null, null, 'Strict')

        },
        logout(state) {
            state.logined = false;
            Vue.$cookies.remove('user_session');
        }
    },
    actions: {
        login({ commit }) {
            commit('login');
        },
        logout({ commit }) {
            commit('logout');
        },
        refreshToken({ commit }) {
            axios.get("/api/refreshToken").then(
                response => {
                    commit('login', response.data);
                }
            ).catch(error => {
                console.log(error)
            })
        },
    },
    getters: {
        logined: state => state.logined
    }

});