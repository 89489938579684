import DashboardIndex from '../pages/DashboardIndex.vue'
import NotFound from '../pages/NotFound.vue'

const routes = [{
        path: '/',
        component: DashboardIndex,
        redirect: '/Dashboard',

    },
    {
        path: '/Dashboard',
        component: DashboardIndex,
        name: 'dashboard',

    },
    {
        path: '/ChartOfAccounts',
        // lazy load component
        component: () =>
            import ('../pages/ChartOfAccounts.vue'),
        name: 'ChartOfAccounts',
    },
    {
        path: '/Invoicing',
        // lazy load component
        component: () =>
            import ('../pages/Invoicing.vue'),
        name: 'Invoicing',
    },
    {
        path: '/Purchasing',
        // lazy load component
        component: () =>
            import ('../pages/Purchasing.vue'),
        name: 'Purchasing'
    },
    {
        path: '/BankAccounts',
        // lazy load component
        component: () =>
            import ('../pages/BankAccounts.vue'),
        name: 'BankAccounts'
    },  
    { path: '*', component: NotFound }
]


export default routes