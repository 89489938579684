<template>
    <b-overlay :show="loading" style="
              max-width: 1280px;
              margin: auto;
              padding: 1em;
              min-height: 100vh;
              background-color: white;
            ">
            <div style="display: flex; justify-content: space-between; align-items: center" class="mb-2">
            <h1>Home</h1>
        </div>
        <b-card title="Invoice Awaiting Payments / Amount Unmatched">
        b-table showing all invoices awaiting payments
        </b-card>
        <b-card title="Purchases Awaiting Payments / Amount Unmatched" class="mt-2">
        b-table showing all Purchases awaiting payments
        </b-card>
        <b-card title="Bank Transactions Awaiting Match" class="mt-2">
        b-table showing all Bank Transactions awaiting match
        </b-card>
    </b-overlay>
</template>

<script>

export default {
    name: "PurchasingIndex",
    data() {
        return {
            loading: false,
            editingId: null,
            groups: [],
            fields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                    thStyle: "width:50%",
                    tdClass: "align-middle",
                },
                {
                    key: "balance",
                    label: "Balance",
                    sortable: true,
                    thStyle: "width:30%",
                    tdClass: "align-middle",
                },
                { key: "actions", thStyle: "width:20%", tdClass: "align-middle" },
            ],
            showAddModal: false,
            newAccount: {
                type: "",
                name: "",
                startingBalance: "",
            },
            accountTypes: [],
        };
    },
    mounted() {
    },
    methods: {
 
    },
};
</script>
