<template>
    <div style="background-color:#eee"> 
        <CommonHeader/>
    <slot/>
    </div>
</template>

<script>
import CommonHeader from './CommonHeader.vue';
export default {
    name: 'defaultLayout',
    components: {
        CommonHeader
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
