import axios from 'axios'
import store from './store/store'

const baseUrl = window.location.origin == 'https://app.uniquelygroup.com' ? 'https://api.uniquelygroup.com' : 'https://dev-api.uniquelygroup.com';

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 6000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

instance.interceptors.request.use(function(config) {
    // Do something before request is sent
    if (store.getters.logined) {
        config.headers.Authorization = `Bearer ${store.state.access_token}`;
    }

    return config;
})

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status == 401 && error.response.data.message == 'Unauthenticated.') {
        store.dispatch('logout');
    }
    return Promise.reject(error);
});

export default instance